@import url(https://fonts.googleapis.com/css2?family=Mukta+Vaani&display=swap);
body{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #EDEDEE;
}

a{
  color: inherit;
}

.list-group-flush{
  color: #495057;
}

.active{
  background-color: #e9ecef !;
}

main {
  background-color: #EDEDEE;
}

.navbar-brand{
  margin-left: 15px;
  color: #2196f3 !important;
}

.sidebar-fixed {
  left: 0;
  top: 0;
  height: 100vh;
  width: 270px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 1050;
  background-color: #fff;
  padding: 1.5rem;
  padding-top: 0;
}


.flexible-navbar {
  transition: padding-left 0.5s;
  padding-left: 270px;
  background: #fff;
}

#content{
  margin-left: 270px;
}    


.sidebar-fixed .logo-wrapper img{
  width: 100%;
  padding: 2.5rem;
}

.list-group-item {
  display: block !important;
  transition: background-color 0.3s;
}

.list-group-item:hover {
  color: #49505B;
  text-decoration: none;
  background-color: #f8f9fa
}

.list-group-item:hover {
  color: #49505B;
  text-decoration: none;
  background-color: #f8f9fa
}


.list-group .active {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.card-title{
  font-size: 1.5rem;
  font-weight: 600;
}

.card-subtitle{
  font-size: 1.25rem;
  font-weight: 600;
}

.full {
  height: 70vh;
}

.bad-gateway-row {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}


.card .view.gradient-card-header {
  padding: 1rem 1rem;
  text-align: center;
}

.card h3, .card.card-cascade h4 {
  margin-bottom: 0px;
}

.cascading-admin-card .admin-up {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: -20px;
}

.cascading-admin-card .admin-up .fa {
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19);
}

.cascading-admin-card .admin-up .fa {
  padding: 1.7rem;
  font-size: 2rem;
  color: #fff;
  text-align: left;
  margin-right: 1rem;
  border-radius: 3px;
}

.cascading-admin-card .admin-up .data {
  float: right;
  margin-top: 2rem;
  text-align: right;
}
.admin-up .data p {
  color: #999999;
  font-size: 12px;
}
.classic-admin-card .card-body {
  color: #fff;
  margin-bottom: 0;
  padding: 0.9rem;
}
.classic-admin-card .card-body p {
  font-size: 13px;
  opacity: 0.7;
  margin-bottom: 0;
}
.classic-admin-card .card-body h4 {
  margin-top: 10px;
}

.form-inline{
  flex-flow: unset
}

.breadcrumb{
  margin: 0;
}

.activeClass .list-group-item {
  z-index: 5;
  color: #fff;
  border-color: #007bff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px !important;
  background-color: #007bff !important;
}

.page-footer{
  margin-left: 270px;
}

@media (max-width: 1199.98px) {
  .sidebar-fixed {
    display: none;
  }
  .flexible-content {
    padding-left: 0;
  }
  .flexible-navbar {
    padding-left: 10px;
  }

  #content{
    margin-left: 0px;
  }

  .page-footer{
    margin-left: 0px;
  }
  
  .card.cascading-admin-card {
    margin-top: 2.5rem;
  } 
}

@media (max-width: 576px) { 
  .card.cascading-admin-card {
    margin-top: 2.5rem;
  }

  #breadcrumb{
    flex-direction: column;
  }
}





.space{

    margin-top:-35px;
}
.spacestate{
    margin-top:-20px;
}
.districtspace{
    margin-top: -20px;
}
.Talukaspace{
    margin-top:-27px;
}

.Cityspace{
    margin-top:-10px;
}
#select{font-size: 13px;}
.bgcolor{
  background-color: #DAD8D8;
  font-size: 14px;
  height: 30px;
   line-height: 32px;
  
  
    
   
}
.Savedata{
    font-weight: bold;
    
    

}
.VehicleCategory{
  Margin-top:-20px;
    
}
.modelcategory{
    Margin-top:-15px;
      
  }
  .modelDiscription{
    Margin-top:-40px;
  }
  .personalinfospace{
      Margin-top:-15px;
  }
  
  .purchasespace{
    Margin-top:-22px;
  }
  .AddPurchaseModel{
    Margin-top:-20px;
  }
  .AddRcSpace{
    Margin-top:-20px;
  }
  .userspace{
      Margin-top:-22px;
  }
  
  .CustomBreadCrumb{
   
    margin-left: 172px;
    margin-top:-12px;
  }
  .contactCustomBreadCrumb{
    margin-left: 178px;
    margin-top:-12px;
  }
  .StateCustomBreadCrumb{
    margin-left: 188px;
    margin-top:-12px;
  }
  .CustomBreadCrumbtaluka{
   
    margin-left: 195px;
    margin-top:-12px;
  }
  .CompanyCustomBreadCrumb{
    margin-left: 175px;
    margin-top:-12px;
  }
  .CustomBreadCrumbcity{
   
    margin-left: 210px;
    margin-top:-12px;
  }
  .CustomBreadCrumbadd{
    margin-left: 280px;
    margin-top:-12px;
  }
  .CustomBreadCrumbaddRC{
    margin-left: 100px;
    margin-top:-20px;
  }
  #VehicleCategory{
    margin-left: 110px;
  }
  #ContactModel{
    margin-left: 60px;
  }
  .personalinfoselect{
    margin-top: -10px;
  }
  #UserModel{
    margin-left: 40px;
  }
  #partyvoucher{
    margin-left:139px;
  }
  .Purchasemargin{
    margin-top: -30px;
  }
  .CalendorIcon{
   margin-left: 150px;
   
  }
  .spaceing{
    margin-top: -20px;
  }
  .spacecheck{
    margin-top:-12px;
  }
  .checkboxpadding{
    padding-top: 33px;
    margin-left: 10px;
  }
  
  
  
  .alldoccheckbox{
    margin-top:20px;
    margin-left:10px;
}
input[type="text"][disabled] {
 background-color: rgb(243, 236, 236);
}
  .statepadding{
    margin-left: 30px;
  }
  .CustomBreadCrumbaddvoucher{
    margin-left: 340px;
  }
  .CustomBreadCrumbvehiclemodel{
    margin-left: 186px;
  }
  .UserMasterCustomBreadCrums{
    margin-left: 178px;
    margin-top:-12px;
  }
  .Cancelbutton{
    margin-top: 22px;
    margin-left: -100px;
  }
  .StateCancelbutton{
    margin-top: 22px;
    margin-left: -55px
  }
  .talukatabletopmargin{
    margin-top: -25px;
  }
  
  .SaveCancelbutton{
    margin-left: -20px;
    margin-top: -20px;
  }
  .error label {
    color: #cc0033;
  }
  .error-message {
    color: #cc0033;
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    margin: 5px 0 0;
  }
  .searchmargin{
    margin-top: -45px;
  }
  
  .citysearchmargin{
    margin-top: -15px;
  }
  .companysearchmargin{
    margin-top:-55px;
  }
  .searchicon{
    margin-top:-20px;
  }
  .countrysearchicon{
    margin-top:-10px;
    
  }
  .input-group.md-form.form-sm.form-2 input.lime-border {
  border: 1px solid #e6e3e3;
  box-shadow: 2px 2px #e6e3e3;
  
}

#DistrictCustomBreadCrumb{
   
  margin-left: 178px;
  margin-top:-12px;
}
.DistrictCancelbutton{
  margin-left: 40px;
  margin-top: 20px;
}
.districttable{
  margin-top:-12px;
  
}

.talukarowmargin{
  margin-top: -35px;
}
.contactsavebutton{
  margin-left:-20px;
  margin-top: 18px;
  width: 106px;
}

.searchtopmargin{
  margin-top:-20px;
}
.purchasesearchicon{
  
 margin-top: -20px;
  

}
.Rccheckbox{
  margin-top:35px;
 padding-left: 25px;
}
.Rcsavebutton{
  margin-left:-20px;
}
.Rcsearch{
  margin-top:-15px;
}
.partyvocuhersearch{
  margin-top: 22px;
  margin-left: -10px;
}
.partyvouchertable{
  margin-top:-25px;
}
.rcmasterbreadcrumbs{
  margin-left: 218px;
}
.aimscenter{
  margin-left: 90px;
  color: gray;
}





.cityName{
  width: 170px;
}
/*
.DistrictName{
  width:105px;
}
*/

.AddpurchaseRadio{
  margin-left: 10px;
  
 
}
.Addpurchasecheckbox{
  margin-left: 12px;
}
.radiochecked{
  margin-top:-10px;
  margin-left: 14px;
}
.purchasedetailmargin{
  margin-left: -20px;
}
.purchasedetailmarginlabel{
  margin-left:8px;
}
.purchasedetailmarginlabe{
  margin-left:-20px;
}
.purchasedetailcompany{
  margin-left: -90px;
}
.purchasedetailfor{
  margin-left: -50px;
}
.purchasedetailmarginfor{
  margin-left: -34px;
}
.purchasedetailmarginforscrap{
  margin-left:6px;
}
.purchasedetailfor{
  margin-left: 30px;
}
.purchasedateymodelyearlabel{
  margin-left:12px;
}
.purchasedetailmodelyearforvender{
  margin-left: -34px;
}
.purchasevendor{
  margin-left: 8px;
}
.purchaseAmout{
  margin-left:-80px;
}
.purchaseamountmargin{
  margin-left:-px ;
}
.contactmobile{
  margin-left: 15px;
}
.purchasedetailmarginlabe{
  margin-left: -16px;
}
.stockdata{
  margin-left: -46px;
}
.rcbookid{
  margin-left: -12px;
}

.checkmark {
 
 
  
  height: 20px;
  width: 20px;
 
}
.rdrInputRanges{
  display: none;
}
.rdrDateRangeWrapper{
  width:55%;
  margin-top: -15px;
}
.rdrDefinedRangesWrapper{
  width: 105px !important;
}

@media(max-width:468px){
 
  .rdrStaticRanges{
    flex-direction: row !important;
    font-size:10px !important;
  }
  
  .rdrDateDisplayWrapper{
    margin-top: 25px !important;
  }
  .rdrStaticRangeLabel{
    padding: 10px 12px !important;
  }
  .rdrWeekDay{
    margin-left: 3px !important;
  }
  .savebutton{
    margin-top: -20px;
    padding-bottom: 20px;
  }
  
  .AddFilter{
    margin-left: -8px;
  }
  
  .countrycancelbutton{
    width: 100%;
    margin: auto;
    margin-top: 20px;
    
  }
  .selectstate{
    margin-top: -30px;

  }
  .selectstate input{
    width: 100%;
  }
  .Statesavebutton{
    width: 100%;
    margin-left: -2px;
    margin-top: -10px;
    
  }
  .Districtsavebutton{
    width: 100%;
    margin-left: -2px;
    margin-top: -10px;
    
  }
  .Statesavecancel{
    width: 100%;
    margin-top: -10px;
    margin-left: auto;
  }
  .Districtsavecancel{
    width: 100%;
    margin-top: -10px;
    margin-bottom: 25px;
    margin-left: -0px;
  }
  .citysavecancel{
    width: 100%;
   
    margin-bottom: 25px;
    margin-left: -0px;
  }
  .DistrictState{
    margin-top:-25px;
  }
  .DistrictName{
    margin-top: -25px;
  }
  .citySaveButton{
    width: 100%;
  }
  
  .talukaName{
    margin-top: -30px;
  }
  .companysavebutton{
    width: 100%;
    margin-left: -0px;
  }
  .companycancelbutton{
    width: 100%;
    margin-top: 35px;
    margin-left: -0px;
  }
  .talukasavebutton{
    width: 100%;
    margin-top: -20px;
  }
  .talukacancelbutton{
    width: 100%;
   
  }
  .selectmodeltop{
    margin-top: -15px;
  }
  .vehicalmodelsavedata{
    width: 100%;
  }
  .vehiclemodelcancelsavedata{
    width: 100%;
   
  }
  
  .contactsavebutton{
    width: 100%;
    
  }
  .browsedata{
    width: 220px;
    margin-left: 15px;
  }
  .ContactSave{
    width:100%;
    margin-top: -40px;
  }
  .contactcancel{
    width: 100%;
    margin-top: -10px;
  }
  .addresssavebutton{
    width: 100%;
    margin-top: -10px;
    box-sizing: border-box;
  }
  .addresscancelbutton{
    width: 100%;
  }
  .purchasesave{
    width: 80%;
   
  }
  .purchasecancel{
    width: 100%;
    margin-left: 0px;
    
  }
  .purchaseaddnew{
    width: 100%;
    margin-left: 0px;
    
    margin-top: 0px;
  }
  .scrapexport{
    margin-left: auto;
    margin-top: 0px;
    width: 100%;
  }
  .scraptable{
    margin-top: 10px;
  }
  .partyvocuhersearch{
    width: 100%;
  }
  .partyvouchersearch{
    width: 100%;
    margin-top: -15px;
    margin-left: 10px;
    
  }
  .Usercontactsavebutton{
    width: 100%;
    margin-left: -10px;
    margin-top: 5px;
  }
  .miscApplyFilter{
    width: 100%;
    margin-left: -0px;
  }
  .miscClearFilter{
    width: 100%;
    margin-top: -10px;
    margin-left: -0px;
  }
  
  .purchasecheckbox{
    margin-left: 5px;
  }
  .vehicaleModelCard{
    height: 1100px;
  }
  .contactdata{
    height: 600px;
  }
  .Filterbutton{
    width: 100%;
    margin: auto;
   
    
   
  }
  .addscrappurchasenew{
    width: 100%;
     margin-left: 0px;
     
   }
   .purchaseaddnew{
     width: 100%;
   }
   .Rcapplyfilter{
     width: 100%;
     margin: 0px;
   }
   .addscrapcarddata{
     height: 1000px;
   }
   .carddata{
    height: 1400px;
  }
  .buttondata{
    width: 100%;
    margin: auto;
    margin-top: 10px;
  }
  
  .addsalessavedata{
    width: 100%;
  }
 .addsalescanceldata{
   width: 100%;
  
 }
 .addrcsavedata{
   width: 100%;
   margin-left: -2px;

 }
 .addrccanceldata{
   width: 100%;
   margin-left: -2px;
 }
 .purchaseinvantorysave{
  width: 100%;
  margin-left: -2px;
  margin-top: 15px;
}
.purchaseinventoryCancal{
 width: 100%;
 margin-left: -2px;
 margin-top: 15px;

}
.purchaseCancal{
  width: 100%;
  margin: auto;
}
 
 
 .filedatastyle{
  position: absolute;
  top:5;
  right: 0;
  left: 5;
  
  z-index: 2;
  background-color:  #e9ecef;
  font-size: 0.9rem;
  border: 1px solid  #ced4da;
  line-height: 1.5;
  height: calc(1.7em + .77rem + 2px);
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  font-weight: 400;
  overflow: visible;
  padding: .385rem .75rem;
}
.inputbrowsedata{
  width: 100%;
 
  height: calc(1.5em + .75rem +2px);
  margin: 0px;
  opacity: 0px;
  z-index: 2;
}
.filedatastyledata{
  position: absolute;
  top:30px;
  right: 0;
  left: 5;
 z-index: 1;
  background-color:  #e9ecef;
  font-size: 0.9rem;
  border: 1px solid  #ced4da;
  line-height: 1.5;
  height: calc(1.7em + .75rem + 2px);
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  font-weight: 400;
  overflow: visible;
  padding: .385rem .75rem;

}
.reportapplybutton{
  width: 100%;
  margin: auto;
}
.reportclearbutton{
  width: 100%;
  margin: auto;
}

.addscrapdata{
  height: 1000px;
}
.destroycarddata{
  height: 950px;
}
.salesreciptcarddata{
  height: 850px ;
}
.profitlossApplyFilter{
  margin-top: 25px;
}
.profitlossclearfilter{
  margin-top: 25px;
}
.profitlossApplyFilter{
  width: 100%;
 margin: 0px;
 margin-top: 10px;
 }
 .profitlossclearfilter{
   width: 100%;
   margin: 0px;
   margin-top: 10px;
 }
 .profitlosscarddata{
   height: 1000px;
 }
 .citycarddata{
  height: 600px;
}

.addfiltervalue{
  width: 100%;
  margin: auto;
}
.cleardue{
  width: 100%;
  margin: auto;
}
 
  
}

@media(max-width:767px){
  .rdrDateRangePickerWrapper{
    display: inline !important;
    font-size:10px !important;
  }
  .cleardue{
    width: 100%;
    margin: auto;
  }
  .purchaseCancal{
    width: 100%;
    margin: auto;
  }
  
  .destroycarddata{
    height:1250px;
  }
  .Filterbutton{
    width: 100%;
    margin: auto;
   
    
   
  }
  
  
  .rdrDateDisplayWrapper{
    margin-top: 20px !important;
  }
  .profitlossApplyFilter{
   width: 100%;
  margin: 0px;
  margin-top: 10px;
  }
  .profitlosscarddata{
    height: 1000px;
  }
  .profitlossclearfilter{
    width: 100%;
    margin: 0px;
    margin-top: 10px;
  }
  .addscrapcarddata{
    height: 1200px;
  }
  
  .rdrWeekDay{
    margin-left: 3px !important;
  }
  .addscrapdata{
    height: 1000px;
  }
  .Rcapplyfilter{
    width: 100%;
    margin: 0px;
  }
  .carddata{
    height: 1400px;
  }
  
  .filedatastyle{
    position: absolute;
    top:5;
    right: 0;
    left: 5;
    
    z-index: 2;
    background-color:  #e9ecef;
    font-size: 0.9rem;
    border: 1px solid  #ced4da;
    line-height: 1.5;
    height: calc(1.7em + .77rem + 2px);
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    font-weight: 400;
    overflow: visible;
    padding: .385rem .75rem;
  }
   
    
   
    
   
 
  
  .Rcclearfilter{
    width: 100%;
    margin: 0px;
    margin-top: 8px;
  }
  .countrysavebutton{
    width: 100%;
    margin: auto;
  }
  .addressTypeSaveButton{
    width: 100%;
    margin: auto;
  }
  .addressTypeCancelButton{
    width: 100%;
    margin: auto;
    margin-top: 20px;
    
  }
  .countrycancelbutton{
    width: 100%;
    margin: auto;
    margin-top: 20px;
    
  }
  
  .citysavebutton{
    margin-top: -15px;
    width: 100%;
    margin-left: -0px;
  }
  .citysavecancel{
    width: 100%;
   
    margin-bottom: 25px;
    margin-left: -0px;
  }
  .companysavebutton{
    width: 100%;
    margin-left: -0px;
  }
  .companycancelbutton{
    width: 100%;
    margin-top: 35px;
    margin-left: -0px;
  }
  .talukasavebutton{
    width: 100%;
    margin-top: -20px;
    margin-left: 0px;
  }
  .talukacancelbutton{
    width: 100%;
    margin-left: 0px;
  }
  .selectmodeltop{
    margin-top: -15px;
  }
  .vehicalmodelsavedata{
    width: 100%;
  }
  .vehiclemodelcancelsavedata{
    width: 100%;
  
  }
  .contactsavebutton{
    width: 100%;
    margin-top: -10px;
    margin-bottom: 25px;
    margin-left: -8px;
  }
  .browsedata{
    width: 265px;
    margin-left: 15px;
  }
  .ContactSave{
    width:100%;
    margin-top: -40px;
  }
  .contactcancel{
    width: 100%;
    margin-top: -10px;
  }
  .addresssavebutton{
    width: 100%;
    margin-top: -10px;
    box-sizing: border-box;
  }
  .addresscancelbutton{
    width: 100%;
  }
  
  .purchasesave{
    width: 100%;
    margin-left: 0px;
   
  }
  .purchasecancel{
    width: 100%;
    margin-left: 0px;
    
  }
  .purchaseaddnew{
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
  }
  .scrapexport{
    margin-left: auto;
    width: 100%;
    margin-top: 0px;
  }
  .scraptable{
    margin-top: 10px;
  }
  .Addpurchasesave{
    width: 100%;
    
  }
  .AddpurchaseCancel{
    width: 100%;
   
  }
  
  .AddpurchaseRc{
    width: 100%;
   
  }
  .rcsave{
    width: 100%;
    margin-left: -0px;
  }
  .rccancel{
    width: 100%;
    margin-left: 0px;
  }
  .salessave{
    width: 100%;
    margin-left: -0px;
  }
  .salescancel{
    width: 100%;
    margin-left: -5px;

  }
  .salesclearDue{
    width: 100%;
    margin-left: 0px;
  }
  .Usercontactsavebutton{
    width: 100%;
    margin-left: -10px;
    margin-top: 5px;
  }
 
  .addusercanceldata{
    width: 100%;
  }
  
  .Receiptvocuhersearch{
    width: 100%;
    margin-top: -20px;
    margin-left: 12px;
  }
  .partyvouchersearch{
    width: 100%;
    margin-top: -15px;
    margin-left: 10px;
  }
  .miscApplyFilter{
    width: 100%;
    margin-left: -0px;
  }
  .miscClearFilter{
    width: 100%;
    margin-top: -10px;
    margin-left: -0px;
  }
  .reportApplyFilter{
    width: 100%;
    margin-left: -0px;
  }
  .reportclearfilter{
    width: 100%;
    margin-left: -0px;
  }
  .purchasecheckbox{
    margin-left: 5px;
  }
  .vehicaleModelCard{
    height: 1100px;
  }
  .addscrappurchasenew{
    width: 100%;
     margin-left: 0px;
     
   }
   .destroysave{
     width: 100%;
     margin-left: auto;
   }
   .destroycancel{
     width: 100%;
     margin-left: auto;
   }
   .openAddPurchase{
     width: 100%;
   }
   .purchaseaddnew{
    width: 100%;
  }
  .scrapbuttondata{
    width: 100%;
    margin: auto;
    margin-top: 10px;
  }
  .scrapaddnew{
    width: 100%;
    margin: auto;
    margin-top: 10px;
  }
 
  .addsalessavedata{
    width: 100%;
  }
 .addsalescanceldata{
   width: 100%;
  
 }
 .Statesavecancel{
   width: 100%;
   margin-top: -10px;
   margin-left: auto;
 }
 .vehicalmodelsavedata{
   margin-top: 20px;
  
 }
 .addusersavedata{
   margin-top: 30px;
  width: 100%;
 }
 .purchaseinvantorysave{
   width: 100%;
   margin-left: -2px;
   margin-top: 15px;
 }
 .purchaseinventoryCancal{
  width: 100%;
  margin-left: -2px;
  margin-top: 15px;

 }
 .addmislanioussavedata{
   width: 100%;
 }
 .addmislaniouscanceldata{
   width: 100%;
 }
 .inputbrowsedata{
  width: 100%;
 
  height: calc(1.5em + .75rem +2px);
  margin: 0px;
  opacity: 0px;
  z-index: 2;
}
.filedatastyledata{
  position: absolute;
  top:30px;
  right: 0;
  left: 5;
 z-index: 1;
  background-color:  #e9ecef;
  font-size: 0.9rem;
  border: 1px solid  #ced4da;
  line-height: 1.5;
  height: calc(1.7em + .75rem + 2px);
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  font-weight: 400;
  overflow: visible;
  padding: .385rem .75rem;

}
.reportapplybutton{
  width: 100%;
  margin: auto;
}
.reportclearbutton{
  width: 100%;
  margin: auto;
}
.purchasevouchercarddata{
  height: 1400px;
}

.salesreciptcarddata{
  height: 850px;
}

.balansheetreportdata{
  height: 1100px;
}
.mislaniouscarddata{
  height: 1000px;
}

.addsalescarddata{
  height: 3600px;
}


.sellcarddata{
  height: 1000px;
}
.addpurchasecarddata{
  height: 3000px;
}
.addrccarddata{
  height: 1900px;
}
.inventorysavedata{
  height: 100px;
}
.contactmodelsavevalue{
  width: 100%;
 margin-left: 5px;
}
.concactmodelcancelvalue{
  width: 100%;
  margin-left: 5px;

}
.contactmodelcard{
 height: 1700px;
}
.remindarcarddata{
  height: 1400px;
}
.miscdocscardvaluedata{
  height: 1400px;
}




.citycarddata{
  height: 1200px;
}
.Statesavebutton{
  width: 100%;
  margin-left: -2px;
  margin-top: -10px;
  
}

.Districtsavebutton{
  width: 100%;
  margin-left: -2px;
  margin-top: -10px;
  
}
.Districtsavecancel{
  width: 100%;
  margin-top: -10px;
  margin-bottom: 25px;
  margin-left: -0px;
}
.addfiltervalue{
  width: 100%;
  margin: auto;
}
 

 
}


@media(min-width:768px) and (max-width:1023px){
  .selectstate{
    margin-left: -20px;
  }
  .contactmodelcard{
      height: 1100px;
  }
  .addfiltervalue{
    margin-left: 40%;
  }
  .addressTypeSaveButton{
    margin-top: 30px;
  }
  .addressTypeCancelButton{
    margin-top: 28px;
  }
  .browsedata{
    width: 200px;
  
  }
  
  
  .addrccarddata{
        height: 1000px;
  }
  .addrcdata{
    height: 1800px;
  }
  .profitlosscarddata{
    height: 1200px;
  }
  .destroycarddata{
    height: 1200px;
  }
  .addscrapdata{
    height: 1200px;
  }
  .reportapplybutton{
    margin-left: 25px;
    margin-top: 28px;
  }
  .reportclearbutton{
   
    margin-top: 28px;
    
  }
  .purchaseaddnew{
    width: 105px;
    margin-top: 20px;
   
  }
  .cardvalue{
    height: 1500px;
  }
  
  .scrapexport{
    margin-top: 20px;
    margin-left: -20px;
  }
  
  .ContactSave{
    margin-top: 10px;
  }
  .contactcancel{
    margin-left: -230px;
    margin-top: 10px;
  }
  
  .Statesavebutton{
    width: 380%;
    margin-top: -10px;
    margin-left: 2px;
  }
  .Statesavecancel{
    width: 380%;
   
    margin-left: 2px;
  }
  
   
  
  .Districtsavebutton{
      width: 110px;
      margin-left: -26px;
      margin-top: 29px;
  }
  .DistrictState{
    margin-left: -26px;
  }
  .DistrictName{
    margin-left: -22px;
  }
  .Districtsavecancel{
    margin-top: 28px;
    margin-left: -20px;
  }
  .talukaName{
    margin-top: -30px;
  }
  .talukasavebutton{
    margin-top: -2px;
    margin-left: -25px;
  }
  .talukacancelbutton{
    margin-top: -3px;
    margin-left: -80px;
  }
  .citysavebutton{
    margin-top: -20px;
    width: 100%;
    margin-left: 0px;
 
  }
  .citysavecancel{
    margin-top: -23px;
    margin-left: -20px;
    
  }
  .companysavebutton{
    margin-top: 25px;
  }
  .companycancelbutton{
   margin-top: 25px;
    margin-left: -140px;
  }
  
 
  
.selectmodel{
    margin-left: -20px;
  }
  .modelName{
    width: 80px;
  }
  .browsefile{
    margin-top: 30px;

  }
  .addresssavebutton{
    width:120px;
    margin-top: 30px;
    
  }
  .addresscancelbutton{
   
    margin-top: 30px;
    margin-left: -30px;
  }
  .purchasesave{
   
   
  }
  .purchaseCancal{
    margin-top: 20px;
   
    
  }
  .purchaseamount{
    width: 180px;
  }
  .vendordelear{
    margin-left: 160px;
  }
  
  .rcsave{
    width: 140px;
    margin-left: -24px;
    margin-top: 28px;
  }
  .rccancel{
    width: 140px;
    margin-left: 15px;
    margin-top: 28px;
  }
  .rccarddata{
    height: 900px;
  }
  .salessave{
    width: 140px;
    margin-top: 27px;
  }
  .salescancel{
    margin-left: 90px;
    width: 150px;
    margin-top: 26px;
  }
  .salesclearDue{
    margin-left: 190px;
    width: 115px;
    margin-top: 25px;
  }
  .Usercontactsavebutton{
    margin-left: -20px;
    margin-top: 18px;
    width: 105px;
  }
  .Receiptvocuhersearch{
    margin-left: -10px;
  }
  .partyvouchersearch{
    margin-left: -8px;
  }
  .miscApplyFilter{
    margin-left: -0px;
  }
  .miscClearFilter{
    margin-left: -70px;
  }
  .misccarddata{
    height: 1300px;
  }
  .reportcompany{
    margin-left: -30px;
  }
  .reportApplyFilter{
    margin-top: 27px;
    margin-left: -20px;
  }
  .reportclearfilter{
    margin-top: 27px;
    margin-left: -150px;
  }
  .Statesavebutton{
    width: 120px;
  }
  .Statesavecancel{
    width: 120px;
    margin-left: 100px;
    margin-top: -10px;
  }
  .citycarddata{
    height: 1000px;
  }
  .vehicaleModelCard{
    height: 800px;
  }
  .addscrappurchasenew{
    width: 105px;
     margin-left: -20px;
     margin-top: 18px;
   }
  .destroysave{
    margin-top: 30px;
    margin-left: -25px;
  }
  .destroycancel{
    margin-top: 29px;
    margin-left: -110px;
  }
  .carddata{
    height: 1700px;
  }
  .scrapbuttondata{
    margin-top: 30px;
  }
  .scrapaddnew{
    margin-top: 17px;
  }
  .salescarddata{
    height:1800px;
  }
  .addsalescarddata{
    height: 1800px;
  }
  
  .salesrowdata{
    margin-top: 20px;
  }
 
 .Salesdatacancel{
   width: 100%;
   margin: auto;
 }
 .vehiclemodelcancelsavedata{
  margin-top: 25px;
}
.vehicalmodelsavedata{
  margin-top: 25px;
}
.addusersavedata{
  margin-top: 30px;
}
.addusercanceldata{
  margin-top: 30px;
}
.inventerydata{
  margin-top: 27px;
}
.addmislanioussavedata{
  margin-top: 30px;
}
.addmislaniouscanceldata{
  margin-top: 30px;
}

.filedatastyle{
  position: absolute;
  top:5;
  right: 0;
  left: 5;
  
  z-index: 2;
  background-color:  #e9ecef;
  font-size: 0.9rem;
  border: 1px solid  #ced4da;
  line-height: 1.5;
  height: calc(1.7em + .77rem + 2px);
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  font-weight: 400;
  overflow: visible;
  padding: .385rem .75rem;
  
 
  
 
  
 
}
.inputbrowsedata{
    width: 100%;
   
    height: calc(1.5em + .75rem +2px);
    margin: 0px;
    opacity: 0px;
    z-index: 2;
  }
  .filedatastyledata{
    position: absolute;
    top:30px;
    right: 0;
    left: 5;
   z-index: 1;
    background-color:  #e9ecef;
    font-size: 0.9rem;
    border: 1px solid  #ced4da;
    line-height: 1.5;
    height: calc(1.7em + .75rem + 2px);
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    font-weight: 400;
    overflow: visible;
    padding: .385rem .75rem;
  
  }
  .purchasecheckbox{
    margin-left: 15px;
    margin-top: 30px;
  }
  .reportApplyFilter{
    width: 140px;
    margin-left: -20px;
    margin-top: 28px;
  
    
  }
  .profitlossApplyFilter{
    margin-top: 25px;
  }
  .profitlossclearfilter{
    margin-top: 25px;
  }
  .reportclearfilter{
    width: 140px;
    margin-left: 5px;
    margin-top: 24px;
    
  }
  .purchasevouchercarddata{
    height: 1600px;
  }
  .salesreciptcarddata{
    height: 1000px;
  }
  
  .balansheetreportdata{
    height: 1300px;
  }
  
  .mislaniouscarddata{
    height: 1100px;
  }
  .sellcarddata{
    height: 1100px;
  }
  
  .addpurchasecarddata{
    height: 1700px;
  }
  .inventorysavedata{
    height: 1400px;
  }
  .contactmodelsavevalue{
    margin-top: 10px;
  }
  
  .concactmodelcancelvalue{
    margin-left: -24px;
    margin-top: 10px;
  }
  .remindarcarddata{
    height: 1600px;
  }
  .miscdocscardvaluedata{
    height: 1400px;
  }
  
   
  .countrybutton{
    margin-top: 20px;
  }
  
 
 
  
}
@media(min-width:1024px)and (max-width:1366px){
  
  
  .vehicaleModelCard{
    height: 800px;
  }
  .salescarddata{
    height:1600px;
  }
  .addfiltervalue{
    margin-left: 40%;
  }
  .addressTypeSaveButton{
    margin-top: -30px;
  }
  .addressTypeCancelButton{
    margin-top: -30px;
  }
  
  .addsalescarddata{
    height: 1700px;
  }
  .addscrapdata{
    height: 1500px;
  }
  .destroycarddata{
    height: 1700px;
  }
 
  .purchaseaddnew{
    width: 105px;
    margin-top: 28px;
    margin-left: -20px;
  }
  .scrapexport{
    margin-top: 20px;
    margin-left: 20px;
  }
  .purchaseCancal{
    margin-left: 25px;
    margin-top: 26px;
  }
  
  .sign{
    margin-left: 5px;
  }
  .Districtsavebutton{
    margin-top: -24px;
    width: 110px;
  }
  .Districtsavecancel{
    margin-top: -20px;
    margin-left: -20px;
    margin-bottom: 20px;
  }
  .talukaName{
    margin-top: -40px;
   
  }
  .companysavebutton{
    margin-top: 25px;
  }
  .companycancelbutton{
    margin-top: 25px;
    margin-left: -45px;
  }
  .companyleftmargin{
    margin-left: -20px;
  }
  .talukasavebutton{
    margin-top: -13px;
    margin-left: -25px;
  }
  .talukacancelbutton{
    margin-top: -14px;
    margin-left: -60px;
  }
  
 
  .selectmodel{
    margin-left: -20px;
  }
  .modelName{
    width: 80px;
  }
  .browsefile{
    margin-top: 30px;
  }
  .ContactSave{
    margin-top: -20px;
  }
  .contactcancel{
    margin-left: -360px;
    margin-top: -20px;
  }
  .addresssavebutton{
   margin-top: 30px;
  }
  .addresscancelbutton{
    
    margin-top: 30px;
   margin-left: -40px;
    
  }
  .purchasefor{
    margin-left:-20px;
  }
  
  .rcsave{
    width: 140px;
    margin-top: 28px;
    margin-left: -20px;
  }
  .rccancel{
    width: 140px;
    margin-left: 50px;
    margin-top: 28px;
  }
  
  
  .loanaccountno{
    margin-left: 25px;
  }
  .salessave{
    width: 140px;
    margin-top: 28px;
    margin-left: -15px;
  }
  .salescancel{
    width: 150px;
    margin-top: 28px;
    margin-left: -25px;
  }
  .salesclearDue{
    width: 115px;
    margin-top: 5px;
    margin-left: 0px;
  }
  .Usercontactsavebutton{
    margin-left: -20px;
    margin-top: 18px;
    width: 105px;
  }
  .Receiptvocuhersearch{
    margin-left: -10px;
  }
  
  .partyvouchersearch{
  
    margin-left: -8px;
   
  }
  .miscApplyFilter{
    
   
  }
  .miscClearFilter{
   width: 140px;
   margin-left: -150px;
  }
  .misccarddata{
    height: 1500px;
  }
  .reportcompany{
    margin-left: -100px;
    
  }
  
   
  .Statesavebutton{
    width: 110px;
    margin-top: -20px;
  }
  .Statesavecancel{
    margin-left: 90px;
    margin-top: -20px;
  }
  .citysavecancel{
    margin-left: -60px;
    margin-top: -20px;
  }
  .citysavebutton{
    margin-top: -20px;
  }
  .citycarddata{
    height: 1400px;
  }
  .purchaseSelectSize{
    width: 100px;
  } 
  .addscrappurchasenew{
    width: 105px;
     margin-left: -20px;
     margin-top: 18px;
   } 
   .destroysave{
     margin-top: 30px;
     margin-left: -20px;
   }
   .destroycancel{
     margin-left: -180px;
     margin-top: 29px;
   }
   .carddata{
     height: 1700px;
   }
   .buttondata{
    margin-top: 30px;
  }
  .scrapbuttondata{
    margin-top: 30px;
  }
  .scrapaddnew{
    margin-top: 17px;
  }
  .countrybutton{
    margin-top: 20px;
  }
  .addressTypeButton{
    margin-top: 20px;
  }
  .vehiclemodelcancelsavedata{
    margin-top: 25px;
  }
  .vehicalmodelsavedata{
    margin-top: 25px;
  }
  .addusersavedata{
    margin-top: 30px;
  }
  .addusercanceldata{
    margin-top: 30px;
  }
  .inventerydata{
    margin-top: 27px;
  }
  .addmislanioussavedata{
    margin-top: 30px;
  }
  .addmislaniouscanceldata{
    margin-top: 30px;
  }
  
  .filedatastyle{
    position: absolute;
    top:5;
    right: 0;
    left: 5;
    z-index: 2;
    background-color:  #e9ecef;
    font-size: 0.9rem;
    border: 1px solid  #ced4da;
    line-height: 1.5;
    height: calc(1.7em + .75rem + 2px);
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    font-weight: 400;
    overflow: visible;
    padding: .385rem .75rem;
    
   
    
   
    
   
  }
  .inputbrowsedata{
    width: 100%;
   
    height: calc(1.5em + .75rem +2px);
    margin: 0px;
    opacity: 0px;
    z-index: 2;
  }
  .filedatastyledata{
    position: absolute;
    top:30px;
    right: 0;
    left: 5;
   z-index: 1;
    background-color:  #e9ecef;
    font-size: 0.9rem;
    border: 1px solid  #ced4da;
    line-height: 1.5;
    height: calc(1.7em + .75rem + 2px);
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    font-weight: 400;
    overflow: visible;
    padding: .385rem .75rem;
  
  }
  .purchasecheckbox{
   
    margin-top: 40px;
  }
  .reportapplybutton{
    margin-left: 10px;
    margin-top: 30px;
  }
  .reportclearbutton{
    margin-left: 0px;
    margin-top: 30px;
  }
  .purchasevouchercarddata{
    height: 1900px;
  }
  .salesreciptcarddata{
    height: 1400px;
  }
  
  .balansheetreportdata{
    height: 1600px;
  }
  .profitlossApplyFilter{
    margin-top: 25px;
  }
  .profitlossclearfilter{
    margin-top: 25px;
  }
  .profitlosscarddata{
    height: 1500px;
  }
  .mislaniouscarddata{
    height: 1500px;
  }
  .sellcarddata{
    height: 1400px;
  }
  
  .addpurchasecarddata{
    height: 1600px;
  }
  .rcmasterdata{
    height: 1900px;
  }
  
  .inventorysavedata{
    height: 1700px;
  }
  .contactmodelsavevalue{
    margin-top: 35px;
    
  }
  
  .concactmodelcancelvalue{
   
    margin-top: 35px;
   margin-left: -70px;
  }
  .remindarcarddata{
    height: 1900px;
  }
  .miscdocscardvaluedata{
    height: 1600px;
  }
  
  .talukacarddata{
    height: 1300px;
  }
  
   
}
@media(min-width:1200px) {
  .purchaseinvantorysave{
    width: 140px;
    margin-top: 32px;
   
  }
  .addressTypeSaveButton{
    margin-top: 10px;
  }
  .addressTypeCancelButton{
   margin-top: 5px;
  }
  .countrycarddata{
    height: 500px;
  }
  .citycarddata{
    height: 600px;
  }
  .profitlosscarddata{
    height: 700px;
  }
  .mislaniouscarddata{
    height: 600px;
  }
  .salesreciptcarddata{
    height: 600px;
  }
  .addscrapdata{
    height: 700px;
  }
  
  .purchasevouchercarddata{
    height: 1200px;
  }
  .inventerydata{
    margin-top: 0px;
  }
  
  .addusersavedata{
    margin-top: 30px;
  }
  .addusercanceldata{
    margin-top: 30px;
  }
  .salescarddata{
    height: auto;
  }
  .addsalescarddata{
    height: 1000px;
  }
  .vehiclemodelcancelsavedata{
    margin-top: 25px;
  }
  .vehicalmodelsavedata{
    margin-top: 25px;
  }
  
  .addscrappurchasenew{
   width: 105px;
    margin-left: -20px;
    margin-top: 18px;
  }
  .scrapexport{
    margin-left: -10px;
    margin-top: 17px;
  }
  .purchaseinventoryCancal{
    width: 140px;
    margin-top: 32px;
    margin-left: 25px;

  }
  .purchasesave{
    width: 140px;
    margin-left: -20px;
   
  }
  .destroysave{
    width: 140px;
    margin-left: -20px;
    margin-top: 30px;
  }
  .purchaseCancal{
    width: 140px;
    margin-left: -10px;
    margin-top: 28px;
  }
  .destroycancel{
    width: 140px;
    margin-left: 25px;
    margin-top: 29px;
  }
  .addressinfo{
    margin-left: -20px;
    
  }
    .DistrictState{
      margin-left:-26px;
    }
    .DistrictName{
      margin-left: -26px;
    }
    
    .Districtsavebutton{
      width: 110px;
      margin-left: -26px;
      margin-top: 29px;
  }
  .Districtsavecancel{
    margin-top: 28px;
    margin-left: -5px;
    
  }
  .talukasavebutton{
    margin-top: -13px;
    margin-left: -25px;
  }
  .talukacancelbutton{
    margin-top: -14px;
    margin-left: -68px;
  }
  .selectstate{
    margin-left: -20px;
   }
   .companymargin{
     margin-top: 20px;
   }
 .Statesavebutton{
   
    
     margin-top: 28px;
     margin-left: -20px;
   
 }
 .Statesavecancel{
  
     margin-top: 26px;
     margin-left: -50px;
 }
 .countrybutton{
  margin-top: 20px;
 }
 .citysavebutton{
   margin-top: -20px;

 }
 .citysavecancel{
   margin-top: -23px;
   margin-left: -30px;
 }
 .companysavebutton{
   margin-top: 7px;
   width: 110px;
   margin-left: 1px;
   
   
 }
 .companycancelbutton{
   margin-top: 7px;
   margin-left: -12px;
 }
 
 
 .selectmodel{
   margin-left: -20px;
 }
 .modelName{
   width: 130px;
 }
 .sign{
   margin-left: 5px;
 }
 .ContactSave{
   margin-top: -20x;
   margin-left: -0px;
 }
 .contactcancel{
   margin-left: -530px;
   margin-top: -20px;
 }
 .browsefile{
  margin-top: 30px;
}
.addresssavebutton{
 
  margin-top: 32px;
 
}
.addresscancelbutton{
  margin-top: 32px;
  margin-left: -90px;
 
}
.rcsave{
  margin-left: -25px;
 
  margin-top: 28px;
  width: 140px;
}
 
 

.rccancel{
  width: 141px;
  margin-left: 20px;
  margin-top: 28px;
}
.purchasefor{
  margin-left:-20px;
}
.purchasedate{
  margin-left:-20px;
}


.salessave{
  width: 140px;
  margin-top: 28px;
  margin-left: -15px;
}
.salescancel{
  width: 150px;
  margin-top: 28px;
  margin-left: 20px;
}
.salesclearDue{
  width: 115px;
  margin-top: 28px;
  margin-left: 72px;
}
.Usercontactsavebutton{
  margin-left: -20px;
  margin-top: 18px;
  width: 105px;
}
.Receiptvocuhersearch{
  margin-left: -10px;
}
.partyvouchersearch{
  
  margin-left: -8px;
 
}
.miscApplyFilter{
  width: 140px;
  margin-left: -20px;
  margin-top: 28px;
}
.miscClearFilter{
  width: 140px;
  margin-left: 25px;
  margin-top: 28px;
}

.reportcompany{
  margin-left: -80px;
  
}

.reportclearfilter{
  width: 140px;
  margin-left: 5px;
  margin-top: 24px;
  
}

.purchaseSelectSize{
  width: 100px;
}
.Filterbutton{
  margin-top: -10px;
  width: 110px;
  line-height: 10px;
  
  
}
.buttondata{
  margin-top: 30px;
}
.scrapbuttondata{
  margin-top: 30px;
}
.addmislanioussavedata{
  margin-top: 30px;
}
.addmislaniouscanceldata{
  margin-top: 30px;
}

.inputbrowsedata{
  width: 100%;
 
  height: calc(1.5em + .75rem +2px);
  margin: 0px;
  opacity: 0px;
  z-index: 2;
}
.filedatastyle{
  position: absolute;
  top:5;
  right: 0;
  left: 5;
  z-index: 2;
  background-color:  #e9ecef;
  font-size: 0.9rem;
  border: 1px solid  #ced4da;
  line-height: 1.5;
  height: calc(1.7em + .75rem + 2px);
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  font-weight: 400;
  overflow: visible;
  padding: .385rem .75rem;
  
 
  
 
  
 
}


.filedatastyledata{
  position: absolute;
  top:5;
  right: 0;
  left: 5;
 z-index: 1;
  background-color:  #e9ecef;
  font-size: 0.9rem;
  border: 1px solid  #ced4da;
  line-height: 1.5;
  height: calc(1.7em + .75rem + 2px);
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  font-weight: 400;
  overflow: visible;
  padding: .385rem .75rem;

}
.purchasecheckbox{
  margin-left: 15px;
  
}
.slaespurchasecheckbox{

  margin-top: 30px;
}
.reportapplybutton{
  margin-left: 10px;
  margin-top: 30px;
}
.reportclearbutton{
  margin-left: 0px;
  margin-top: 30px;
}
.balansheetreportdata{
  height: 800px;
}
.profitlossApplyFilter{
  margin-top: 25px;
}
.profitlossclearfilter{
  margin-top: 25px;
}
.sellcarddata{
  height: 700px;
}

.addpurchasecarddata{
  height: 800px;
}
.rcmasterdata{
  height: 1100px;
}
.inventorysavedata{
  height: 900px;
}
.contactmodelsavevalue{
  margin-top: 30px;
  
}

.concactmodelcancelvalue{
 
  margin-top: 30px;
  margin-left: -120px;
}
.remindarcarddata{
  height: 1200px;
}
.miscdocscardvaluedata{
  height: 800px;
}

.talukacarddata{
  height: 600px;
}
.addfiltervalue{
  margin-left: 40%;
}




 
}






















.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
  }
  
  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 10px;
  }
  
  .Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }
  
  .Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
  }
  

#select{font-size: 13px;}
.bg-color{
    background-color:rgb(238, 230, 230);
    
   height: 35px;
   line-height: 32px;
}
.Savedata{
    font-weight: bold;

}
.longSqr{
    border-radius:20px;
-moz-border-radius:20px;
-webkit-border-radius:20px;
border-width:2px;
border-style:solid;
border-color:#0e0f0f;
}
.sqr{
    border-radius:20px;
-moz-border-radius:20px;
-webkit-border-radius:20px;
border-width:2px;
border-style:solid;
border-color:#0e0f0f;
}
.striped-border { border: 2px solid rgb(128, 0, 0); width: 100%; margin: auto; margin-top: 1%; margin-bottom: 1%; }
.bootam-border { border-bottom: 2px solid #0e0f0f; width: 100%;margin-bottom: 1%; }
.rowMargin{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}
.fixTopMargin{
    margin-top: 1in !important;
}
.fixBottomMargin{
    margin-bottom: 1in !important;
}
.extraSpace{
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}
.top-bottom-border{
    border-top: 2px solid #0e0f0f; 
    border-bottom: 2px solid #0e0f0f; 
    width: 100%;
    margin-bottom: 1%;
}
.hr { 
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: solid;
    border-width: 1px;
  } 
#divToPrint{
    font-family: 'Mukta Vaani', sans-serif;
    font-size: 18px !important;   
}
.leftdiv{
    float: left;
    
}
.rightdiv{
    float: right;
    
}
.halfwidth{
    width: 50%;
}
.fullwidth{
    width: 100%;
}   
.qrtrwidth{
    width: 33%;
}
.w-20{
    width: 20%;
}
.w-30{
    width: 30%;
}
.w-10{
    width: 10%;
}
.w-15{
    width: 15%;
}
.w-40{
    width: 40%;
}
.w-75{
    width: 75%;
}
.w-90{
    width: 90%;
}
.ml-20{
    margin-left: 20%;
}
.ml-30{
    margin-left: 30%;
}
.ml-15{
    margin-left: 15%;
}
.ml-10{
    margin-left: 10%;
}
.ml-1{
    margin-left: 1%;
}
.ml-3{
    margin-left: 3%;
}
.ml-5{
    margin-left: 5%;
}
.mr-1{
    margin-right: 1%;
}
.mr-3{
    margin-right: 3%;
}
.mr-5{
    margin-right: 5%;
}
.mt-3{
    margin-top: 5%;
}
.mb-0{
    margin-bottom: 0%;
}
.mtpx-10{
margin-top: 10px;
}
.mtpx-20{
    margin-top: 20px;
    }
.dTable{
display: table;
}
.dRow{
    display: table-row;
}
.dCell{
    display: table-cell;
}
.dColumn{
    display: table-column;
}
.boldFont{
    font-weight: bold;
}
.center-text{
    text-align: center;
}
.mainSection{
 height: 7.3in !important;   
 width: 11.7in !important;
}
