@import url('https://fonts.googleapis.com/css2?family=Mukta+Vaani&display=swap');
.longSqr{
    border-radius:20px;
-moz-border-radius:20px;
-webkit-border-radius:20px;
border-width:2px;
border-style:solid;
border-color:#0e0f0f;
}
.sqr{
    border-radius:20px;
-moz-border-radius:20px;
-webkit-border-radius:20px;
border-width:2px;
border-style:solid;
border-color:#0e0f0f;
}
.striped-border { border: 2px solid rgb(128, 0, 0); width: 100%; margin: auto; margin-top: 1%; margin-bottom: 1%; }
.bootam-border { border-bottom: 2px solid #0e0f0f; width: 100%;margin-bottom: 1%; }
.rowMargin{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}
.fixTopMargin{
    margin-top: 1in !important;
}
.fixBottomMargin{
    margin-bottom: 1in !important;
}
.extraSpace{
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}
.top-bottom-border{
    border-top: 2px solid #0e0f0f; 
    border-bottom: 2px solid #0e0f0f; 
    width: 100%;
    margin-bottom: 1%;
}
.hr { 
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: solid;
    border-width: 1px;
  } 
#divToPrint{
    font-family: 'Mukta Vaani', sans-serif;
    font-size: 18px !important;   
}
.leftdiv{
    float: left;
    
}
.rightdiv{
    float: right;
    
}
.halfwidth{
    width: 50%;
}
.fullwidth{
    width: 100%;
}   
.qrtrwidth{
    width: 33%;
}
.w-20{
    width: 20%;
}
.w-30{
    width: 30%;
}
.w-10{
    width: 10%;
}
.w-15{
    width: 15%;
}
.w-40{
    width: 40%;
}
.w-75{
    width: 75%;
}
.w-90{
    width: 90%;
}
.ml-20{
    margin-left: 20%;
}
.ml-30{
    margin-left: 30%;
}
.ml-15{
    margin-left: 15%;
}
.ml-10{
    margin-left: 10%;
}
.ml-1{
    margin-left: 1%;
}
.ml-3{
    margin-left: 3%;
}
.ml-5{
    margin-left: 5%;
}
.mr-1{
    margin-right: 1%;
}
.mr-3{
    margin-right: 3%;
}
.mr-5{
    margin-right: 5%;
}
.mt-3{
    margin-top: 5%;
}
.mb-0{
    margin-bottom: 0%;
}
.mtpx-10{
margin-top: 10px;
}
.mtpx-20{
    margin-top: 20px;
    }
.dTable{
display: table;
}
.dRow{
    display: table-row;
}
.dCell{
    display: table-cell;
}
.dColumn{
    display: table-column;
}
.boldFont{
    font-weight: bold;
}
.center-text{
    text-align: center;
}
.mainSection{
 height: 7.3in !important;   
 width: 11.7in !important;
}